@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda+One&display=swap);
.heroContainer{background-color:black;content:'';width:100vw;position:absolute;top:0;left:0;bottom:0;right:0;z-index:-1}#astro{position:fixed;left:calc(50vw - 80px);top:calc(50vh - 80px);width:160px;height:auto}#earth{width:100%;height:auto;position:fixed;bottom:0;transform:translate(0, 20vw)}#name{position:fixed;font-size:4em;right:0;margin-right:10px;bottom:3rem;text-align:right;font-family:'Orbitron';color:white;letter-spacing:0.3em;-webkit-writing-mode:vertical-rl;-ms-writing-mode:tb-rl;writing-mode:vertical-rl;line-height:0.95;-webkit-animation:inleft 0 1s ease-in;animation:inleft 0 1s ease-in;z-index:-1}.midlight{color:#3E92CC}#subtitle{position:fixed;font-size:2.5em;font-weight:400;left:0;margin-left:10px;top:4.8rem;color:white;font-family:'Orbitron';letter-spacing:0.1em;-webkit-writing-mode:vertical-rl;-ms-writing-mode:tb-rl;writing-mode:vertical-rl;transform-origin:center;max-height:12ch;text-align:right;transform:rotate(180deg)}

.infoBox{width:100%;display:flex;display:none;flex-direction:row;justify-content:space-evenly;font-size:3em;margin:0.5em 0;width:40vw;position:fixed;background-color:#0d0d0d;bottom:10vh;left:calc(50vw - 20vw);padding:0.25em 0}.infoBox a{color:#66a9d7}.infoBox a:hover{color:#dfedf7}@media screen and (max-width: 500px){.infoBox{display:none;width:1em;flex-direction:column;align-items:center;margin:0;left:calc(50vw - 0.5em);padding:0 0.8em}}@media screen and (max-width: 330px){.infoBox{display:none}}

.skills{text-align:center;color:#051335;max-width:80ch;vertical-align:middle}.skills span+span::before{content:'';border-radius:50%;height:0.6ch;width:0.6ch;background-color:#d8315b;display:inline-block;margin:0 0.9ch;margin-bottom:0.3ch}.skill{white-space:nowrap}.skillsContainer{width:100%;display:flex;flex-direction:column;align-items:center;background-color:#7bb4dc;padding:1em 0}@media screen and (max-width: 700px){.skill{white-space:normal;font-size:0.9em}}

.bioSection{position:relative}.bioSection p:first-of-type{text-indent:2em}.bioSection p{max-width:60ch}.emphasize{font-weight:700;font-style:italic;color:#D8315B}

.offsetleft{position:relative;left:-14em}

.contactIconContainer{width:100%;display:flex;flex-direction:row;justify-content:space-evenly;font-size:3em;margin:0.5em 0}

.projectImage{height:300px;min-height:300px;background-position:center center;background-size:contain;background-repeat:no-repeat;background-color:#3E92CC}.cardTitle{letter-spacing:0.3em;font-weight:900;color:#0A2463}.cardText{padding:1em 1em;display:flex;flex-direction:column;height:100%}.cardSubtitle{color:#3E92CC;margin:0.5em 0;font-style:italic}.techContainer{padding:0 1em;margin:1em 0;flex-grow:1}.techContainer .tech{background-color:#1346c0;margin:0 0.3em}.linkContainer{display:flex;padding:0 1rem;flex-direction:row;justify-content:space-between;font-size:3em}.linkContainer a{color:#3E92CC;transition:all 0.3s}.linkContainer a:hover{color:#D8315B}

#navbar{border-bottom:6px solid #3E92CC;font-family:'Merienda One'}.darkNav{background-color:#2c282e}.darkNav .navContent .nav-link{color:#3E92CC}.darkNav .navContent .nav-link:hover{color:#D8315B}.darkNav .navbar-brand{color:white}.lightNav{background-color:#0c2c81}.lightNav .navContent .nav-link{color:#b7d6ec}.lightNav .navContent .nav-link:hover{color:#D8315B}.lightNav .navbar-brand{color:white}.lightNav .active{color:#dc466c !important}

footer{width:100%;line-height:2.5rem;padding-left:2.5rem;border-top:4px solid #3E92CC;background-color:#0A2463;color:#F0EEF0}

*{margin:0;padding:0;box-sizing:border-box}body{font-family:'Open Sans';min-height:100vh;position:relative}h1,h2,h3,h4,h5,h6{font-family:'Merienda One'}.maincontent{display:grid;grid-template-rows:auto 1fr auto;height:100%;min-height:100vh}.min-100{min-height:100%}.largeTopSpace{margin-top:4em}.outline{outline:1px solid red}.whiteBG{background-color:white;padding:2em;border-radius:10px}.darkBG{background-color:#1E1B18;color:#F0EEF0}.projectsContainer{display:grid;align-items:top;grid-template-columns:repeat(auto-fit, minmax(340px, 1fr));grid-gap:3rem}a{color:#D8315B}@media all and (max-width: 600px){.whiteBG{padding:1em 1em}}@media all and (max-width: 767px){.largeTopSpace{margin-top:1em}}

